<template>
  <v-container class="pa-7 myCard111" v-scrollbar>
    <v-form enctype="multipart/form-data">
      <!-- FORM -->
      <v-row>
        <v-col class="text-center">
          <div class="float-md-left" style="margin-top: -1rem">
            <h2>{{ $t("composed.confirmationForm") }}</h2>
          </div>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <!-- INQUIRY TYPE -->
      <v-row class="bottomArrangement111">
        <v-col>
          <span style="color: grey; font-size: 13px">
            {{ $t("composed.inquiryType") }}
          </span>
          <span style="color: grey; font-size: 13px">(</span
          ><span style="color: red; font-size: 10px">{{
            $t("composed.mandatory")
          }}</span
          ><span style="color: grey; font-size: 13px">)</span>
          <v-select
            class="mb-n5"
            v-model="toStore.InquiryType"
            :items="radioValue"
            :item-text="language == 'en' ? 'English' : 'Japanese'"
            item-value="Japanese"
            @change="inquiretype(toStore.InquiryType)"
            color="#df6464"
            outlined
            dense
            ref
          ></v-select>
          <!-- <v-radio-group
            dense
            v-model="toStore.InquiryType"
            width="100%"
            height="5%"
            ref
          >
            <v-radio
              color="#df6464"
              v-for="n in radioValue"
              :key="n.label"
              :label="language == 'en' ? n.Japanese : n.English"
              :value="n.Japanese"
              @change="inquiretype(n.InquiryID)"
            ></v-radio>
          </v-radio-group> -->
        </v-col> </v-row
      ><br />

      <!-- INQUIRY ITEM -->
      <v-row class="bottomArrangement111">
        <v-col>
          <span style="color: grey; font-size: 13px">
            {{ $t("composed.inquiryItem") }}
          </span>
          <span style="color: grey; font-size: 13px">(</span
          ><span style="color: red; font-size: 10px">{{
            $t("composed.mandatory")
          }}</span
          ><span style="color: grey; font-size: 13px">)</span><br />
          <!-- <v-autocomplete
            class="mt-1"
            color="#df6464"
            v-model="toStore.InquiryItem"
            :items="arrInquireItem"
            item-text="InquiryItem"
            item-value="InquiryItem"
            :disabled="arrInquireItem.length == 0 ? true : false"
            dense
            outlined
            clearable
            :label="$t('composed.selectItem')"
            @change="email()"
          > 
         
          </v-autocomplete> -->
          <v-radio-group row>
            <v-radio
              v-for="(val, i) in arrInquireItem"
              :key="i"
              :label="val.InquiryItem"
            ></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <!-- DUE -->
      <v-row class="bottomArrangement111">
        <v-col>
          <span style="color: grey; font-size: 13px">
            {{ $t("composed.due") }} </span
          ><span style="color: grey; font-size: 13px">(</span
          ><span style="color: red; font-size: 10px">{{
            $t("composed.mandatory")
          }}</span
          ><span style="color: grey; font-size: 13px">)</span>
          <v-menu
            content-class="elevation-0"
            v-model="menu"
            class="text-center"
            :close-oncontent-click="false"
            transition="scale-transition"
            offet-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="toStore.TypeOfConfirmation"
                color="#df6464"
                v-bind="attrs"
                v-on="on"
                readonly
                outlined
                dense
                clearable
              >
              </v-text-field>
            </template>
            <v-date-picker
              v-model="toStore.TypeOfConfirmation"
              :allowed-dates="allowedDates"
              @input="menu = false"
              :min="dateToday"
              color="#df6464"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <!-- CUSTOMER CODE -->
      <v-row class="bottomArrangement111">
        <v-col>
          <span style="color: grey; font-size: 13px">
            {{ $t("composed.customerCode") }} </span
          ><span style="color: red; font-size: 10px">{{
            $t("composed.mandatory")
          }}</span>
          <v-text-field
            v-model="customerCode"
            color="#df6464"
            dense
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <!-- DOCUMENT NUMBER -->
      <v-row class="bottomArrangement111">
        <v-col>
          <span style="color: grey; font-size: 13px">
            {{ $t("composed.rulebookNumber") }}
          </span>
          <v-text-field
            v-model="rulebookNumber"
            color="#df6464"
            dense
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <!-- CONTENT -->
      <v-row class="bottomArrangement111">
        <v-col>
          <span style="color: grey; font-size: 13px">
            {{ $t("composed.content") }}
          </span>
          <span style="color: grey; font-size: 13px">(</span
          ><span style="color: red; font-size: 10px">{{
            $t("composed.mandatory")
          }}</span
          ><span style="color: grey; font-size: 13px">)</span>
          <v-textarea
            v-model="content"
            color="#df6464"
            clearable
            counter
            no-resize
            auto-grow
            dense
            outlined
            @keyup="messageContent(content)"
          ></v-textarea>
        </v-col>
      </v-row>
      <br />

      <!-- ATTACHMENT -->
      <v-row class="bottomArrangement111">
        <v-col>
          <!-- <v-file-input
            v-model="files"
            color="#df6464"
            show-size
            counter
            multiple
            :label="$t('composed.attachment')"
            @change="handleFileUploads()"
          ></v-file-input> -->
          <!-- <file-pond
            v-model="files"
            :files="files"
            @change="handleFileUploads()"
            ref="pond"
            :label-idle="$t('composed.attachment')"
            v-bind:allow-multiple="true"
          /> -->
          <file-pond
            v-model="tempFile"
            :onaddfile="handleFileUploads"
            :onremovefile="removeFileUpload"
            ref="pond"
            :label-idle="$t('composed.attachment')"
            allow-multiple="true"
            dropOnPage="true"
          />
        </v-col> </v-row
      ><br />
      <!-- ACTION -->
      <v-row>
        <v-col :cols="window.width >= '697' ? 6 : 12" offset-md="12">
          <v-btn
            width="100%"
            outlined
            :loading="btnLoading"
            color="grey"
            @click.native="SaveDraft"
          >
            {{ $t("composed.clear") }}</v-btn
          >
        </v-col>

        <v-col :cols="window.width >= '697' ? 6 : 12" offset-md="12">
          <v-btn
            color="#00cba3"
            width="100%"
            outlined
            :loading="btnLoading"
            @click="submitEmail"
          >
            {{ $t("composed.send") }}</v-btn
          >
        </v-col> </v-row
      ><br />
    </v-form>

    <!--  SNACKBAR  -->
    <v-snackbar
      v-if="snackbar"
      v-model="snackbar"
      :color="snackbarColor"
      timeout="2000"
      top
    >
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn
          black
          text
          max-width="100"
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <!-- WAITING DIALOG -->
    <v-dialog
      v-model="waitingDialog"
      v-if="waitingDialog"
      persistent
      max-width="200px"
      transition="fade"
    >
      <v-card class="pa-3 text-center">
        <b style="font-size: 15px; font-family: Arial, Helvetica, sans-serif">{{
          language == "en" ? "Plase Wait..." : "お待ちください..."
        }}</b>
        <v-img
          src="../assets/loading.gif"
          width="50"
          height="50"
          style="margin: auto"
        >
        </v-img>
      </v-card>
    </v-dialog>

    <!-- DRAFT DIALOG -->
    <v-dialog v-model="draftDialog" v-if="draftDialog" max-width="400">
      <v-card>
        <v-card-title>
          <v-icon>mdi-content-save-edit</v-icon>
          {{ language == "en" ? "Save Draft" : "下書きを保存" }}</v-card-title
        >
        <v-divider></v-divider>
        <v-text-field
          v-model="draftName"
          @keyup.enter="insertDraft"
          solo
          class="mt-5 ml-5 mr-5 mb-n5"
          :placeholder="
            language == 'en'
              ? 'Enter Draft Name'
              : 'ドラフト名を入力してください'
          "
        ></v-text-field>
        <v-card-actions>
          <v-btn
            color="#00cba3"
            filled
            block
            @click="insertDraft"
            :loading="draftLoading"
          >
            {{ language == "en" ? "Save" : "保存する" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import store from "../store";
import moment from "moment";
import shortid from "shortid";
import attachment from "../functions/Attachment";
import objects from "../functions/alert";
import CategoryInformationTable from "../functions/httpRequest/ExternalConnection/CategoryInformationTable";
import ConfirmationTable from "../functions/httpRequest/ExternalConnection/ConfirmationTable";
import ReplyTable from "../functions/httpRequest/ExternalConnection/ReplyTable";
import EmailTable from "../functions/email";
import DraftTable from "../functions/httpRequest/ExternalConnection/DraftTable";
import InquiryCategory from "../functions/httpRequest/ExternalConnection/InquiryCategory";
import HolidayTable from "../functions/httpRequest/ExternalConnection/HolidayTable";
import EmailTemplate from "../functions/emailTemplate";
import InquiryStatusTable from "../functions/httpRequest/ExternalConnection/InquiryStatusTable";

// Import Vue FilePond
import vueFilePond from "vue-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
// Create component
const FilePond = vueFilePond();

export default {
  data() {
    return {
      waitingDialog: false,
      snackbar: false,
      sendSuccess: false,
      btnLoading: false,
      draftDialog: false,
      draftLoading: false,
      menu: false,
      userType: store.state.userinfo.UserType,
      dateToday: moment().format("YYYY-MM-DD"),
      socket: "",
      snackbarText: "",
      snackbarColor: "",
      split: "",
      splitedText: "",
      radio: "",
      content: "",
      receiveID: "",
      time: "",
      userID: "",
      name: "",
      inquireItemValue: "",
      department: "",
      date: "",
      draftName: "",
      customerCode: "",
      rulebookNumber: "",
      toStore: {},
      holidaySchedule: [],
      s3Key: [],
      tempFile: [],
      files: [],
      attachment: [],
      arrInquireItem: [],
      radioValue: null,
      window: {
        width: 0,
        height: 0,
      },
    };
  },
  components: {
    FilePond,
  },
  async created() {
    await this.getHoliday(); //GETTING HOLIDAY FUNCTION
    await this.getInquiryCategory(); //GETTING INQUIRY CATEGORY FUNCTION
  },
  mounted() {
    // Store Data
    this.toStore.Department = "HRD開発";
    // Handle Screen Size
    window.addEventListener("resize", this.handleResize);
    this.handleResize();

    // Check User Level
    if (store.state.userinfo.EmployeeNumber) {
      this.userID = store.state.userinfo.EmployeeNumber;
      this.name =
        store.state.userinfo.FirstNameEng +
        " " +
        store.state.userinfo.LastNameEng;
    } else {
      this.name = store.state.userinfo.FullName;
      this.userID = store.state.userinfo.EmployeeCode;
    }

    //Include Socket IO
    this.socket = this.$io;
  },

  computed: {
    language() {
      return this.$i18n.locale;
    },

    hrdDepartment() {
      let language = this.$i18n.locale;
      if (this.toStore.InquiryItem) return this.toStore.Department;
      if (language == "ja") return "入力照会項目";
      return "Input Inquiry Item";
    },
  },
  methods: {
    //********************************************************************//
    //   GET HOLIDAY DATES AND VALIDATE ALLOWED DATE OF CONFIRMATION DUE  //
    //********************************************************************//

    getHoliday() {
      HolidayTable.Get().then((res) => {
        this.holidaySchedule = res.data;
        let ConfirmationDate = [];

        // DEFAULT 3 DAYS AFTER SENDING CONFIRMATION
        for (let x = 1; x <= 3; x++)
          ConfirmationDate.push(moment().add(x, "days").format("YYYY-MM-DD"));

        ValidateDate(ConfirmationDate);
      });

      const ValidateDate = (date) => {
        // CHECK IF THERE IS HOLIDAY IN 3 DAY CONFIRMATION DATE
        const checkHoliday = this.holidaySchedule.filter((el) =>
          date.some((date) => el.CalendarDate == date && el.Country == "Local")
        ).length;

        // CHECK IF DATES IS SUNDAY
        const CheckSunday = date.filter((el) => moment(el).day() === 0).length;

        // CHECK IF DATE IS AVAILABLE
        if (checkHoliday === 0 && CheckSunday === 0)
          return this.$set(
            this.toStore,
            "TypeOfConfirmation",
            date[date.length - 1]
          );

        // ADD DAY DEPENDS ON AFFTECTED HOLIDAY OR SUNDAY
        let addtionalDays = [];
        for (let x = 1; x <= checkHoliday + CheckSunday; x++)
          addtionalDays.push(
            moment(date[date.length - 1])
              .add(x, "days")
              .format("YYYY-MM-DD")
          );

        ValidateDate(addtionalDays);
      };
    },

    //**************************//
    //  CHECK FOR DISABLE DATE  //
    //**************************//

    allowedDates(val) {
      // CHECK FOR ALLOWED DATES AND HOLIDAYS
      const check = this.holidaySchedule.filter(
        (el) => el.CalendarDate == val && el.Country == "Local"
      );

      if (moment(val).day() === 0) return false;
      if (check.length > 0) return false;
      else return true;
    },

    /**
     * ASSIGN ALL CATEGORY IN RADIO
     *
     */

    async getInquiryCategory() {
      await InquiryCategory.Get().then(
        (res) =>
          (this.radioValue = res.data.sort((a, b) =>
            a.inquiryID < b.inquiryID ? -1 : 1
          ))
      );
    },
    //**************************//
    //  CHANGE MESSAGE FORMAT   //
    //**************************//

    messageContent(content) {
      this.toStore.MessageContent = content.split("\n").join("<br>");
    },
    //*******************************//
    //  GET INQUIRY ITEM INCHARGE   //
    //******************************//

    async email() {
      if (this.toStore.InquiryItem) {
        const itemValue = await this.arrInquireItem.filter((res) => {
          return res.InquiryItem === this.toStore.InquiryItem;
        });
        // MAIN RECEIVER
        this.toStore.To = itemValue[0].IchijoIncharge;
        this.toStore.Cc = itemValue[0].CcIchijoIncharge;
      }
    },
    //**************************//
    //      SUBMIT RECORD       //
    //**************************//

    async submitEmail() {
      if (!this.toStore.InquiryType)
        this.$toast.error(this.$t("alert.inquiryType"), objects.alert);
      else if (!this.toStore.TypeOfConfirmation)
        this.$toast.error(this.$t("alert.typeOfConfirmation"), objects.alert);
      else if (!this.toStore.InquiryItem)
        this.$toast.error(this.$t("alert.inquiryItem"), objects.alert);
      else if (!this.customerCode)
        this.$toast.error(this.$t("alert.inquiryCustomerCode"), objects.alert);
      else if (!this.toStore.MessageContent)
        this.$toast.error(this.$t("alert.inquiryMessage"), objects.alert);
      else {
        this.btnLoading = true;
        this.waitingDialog = true;
        const GeneratedID = shortid.generate(); //GENERATE CONFIRMATION ID

        // FOR REALTIME SENDING
        this.socket.emit("sendingMessageToServer", {
          name: this.name,
          afftectedUser: this.toStore.To,
          uniqueID: GeneratedID,
          userID: this.userID,
          message: this.toStore.MessageContent,
          time: moment().format("YYYY-MM-DD HH:mm"),
        });

        // PASS VARIABLE TO BE SAVE IN DATABASE
        this.toStore.SenderName = this.name;
        this.toStore.SenderID = this.userID;
        this.toStore.Status = "Not Started";
        this.toStore.DateCreated = moment().format("YYYY-MM-DD HH:mm:ss");
        this.toStore.ConfirmationID = GeneratedID;
        this.toStore.Subject = this.customerCode;
        this.toStore.CustomerCode = this.customerCode;
        if (this.rulebookNumber)
          this.toStore.RuleBookNumber = this.rulebookNumber;

        // CHECK FOR ATTACHMENT
        if (this.attachment.length > 0) {
          const date = moment().format("YYYY/MM/DD");
          this.toStore.Path = `Attachment/${date}`;

          // PASS DATA TO S3 ATTACHMENT FUNCTION
          await attachment.upload(
            "Attachment",
            this.files,
            (fileName) => (this.toStore.Attachment = fileName), //CALLBACK FOR KEYNAME TO BE SAVE IN DATABASE
            async (keyName) => {
              // CALLBACK AFTER SAVING ATTACHMENT IN DATABASE
              this.s3Key = keyName.map((el) => `${this.toStore.Path}/${el}`);
              await sendingEmail();
            }
          );
        }

        // PASS REALTIME REPLY IN BACKEND
        this.socket.emit("inboxMessage", this.toStore);

        let promises = [];

        // CREATE PROMISE FOR SAVING DATA
        if (this.toStore.To)
          promises.push(
            ConfirmationTable.Post(this.toStore),
            ReplyTable.Post(this.toStore),
            InquiryStatusTable.Post(this.InquiryStatus(GeneratedID))
          );

        //CHECK EMAIL ADDRESS AFFECTED IN CONFIRMATION
        const Email = this.arrInquireItem
          .filter(
            (item) =>
              this.toStore.InquiryType == item.Category &&
              this.toStore.InquiryItem == item.InquiryItem
          )
          .map((el) => {
            return {
              Email: el.EmailIchijo || "",
              Receiver: el.IchijoIncharge || "",
              Cc: el.CcIchijo ? JSON.parse(el.CcIchijo).join(",") : "",
            };
          })[0];

        //SENDING EMAIL FUNCTION
        const sendingEmail = async () => {
          await ConfirmationTable.GetCount().then(async (res) => {
            // DATA FOR TEMPLATE
            const data = {
              dataLength: res.data,
              InquiryType: this.toStore.InquiryType,
              Due: this.toStore.TypeOfConfirmation,
              CustomerCode: this.customerCode,
              RuleBookNumber: this.rulebookNumber,
              Content: this.content,
              ConfirmationID: GeneratedID,
              Receiver: Email.Receiver,
            };
            if (Email.Email)
              await EmailTable.send(
                Email.Email,
                Email.Email,
                Email.Cc,
                EmailTemplate.Template(data),
                this.customerCode,
                this.toStore.InquiryItem,
                this.s3Key
              );
            await ClearRecord("success");
          });
        };

        // SEND CALLBACK AFTER CONFIRMATION SEND
        Promise.all(promises)
          .then(() => {
            // IF NO ATTACHMENT CLEAR RECORD
            if (this.attachment.length == 0) sendingEmail();
          })
          .catch(() => ClearRecord("err"));

        // FUNCTION TO CLEAR ALL RECORD
        const ClearRecord = async (notif) => {
          this.waitingDialog = false;
          this.btnLoading = false;
          this.toStore = {};
          this.files = [];
          this.tempFile = [];
          this.arrInquireItem = [];
          this.content = "";
          this.customerCode = "";
          this.rulebookNumber = "";
          this.attachment = [];
          this.$refs.pond.removeFiles();
          this.toStore.Department = "HRD開発";
          this.getHoliday();

          // CHECK IF NOTIF IS ERROR OR SUCCESS
          notif == "success"
            ? this.$toast.success(this.$t("alert.sendSuccess"), objects.alert)
            : this.$toast.error(this.$t("alert.sendError"), objects.alert);
        };
      }
    },

    //*************************************//
    //        FOR INQUIRY STATUS           //
    //*************************************//

    InquiryStatus(ConfirmationID) {
      const data = {
        ReceivedDate: moment().format("YYYY-MM-DD"),
        ReceivedTime: moment().format("HH:mm:ss"),
        DueDate: this.toStore.TypeOfConfirmation,
        SenderStatus: this.userType,
        SenderName: this.name,
        InquiryType: this.toStore.InquiryType,
        InquiryItem: this.toStore.InquiryItem,
        CustomerCode: this.customerCode,
        DocumentNumber: this.rulebookNumber,
        ContentOfInquiry: this.content,
        ConfirmationID: ConfirmationID,
        Type: 1,
      };

      const category = this.arrInquireItem
        .filter(
          (item) =>
            this.toStore.InquiryType == item.Category &&
            this.toStore.InquiryItem == item.InquiryItem
        )
        .map((el) => {
          return {
            HandleStaff: el.IchijoIncharge || "",
            Department: el.Department,
          };
        })[0];
      data.HandleStaff = category.HandleStaff;
      data.Department = category.Department;

      return data;
    },

    //*************************************//
    //     GET AVAILABLE INQUIRY TYPE     //
    //************************************//

    inquiretype(val) {
      this.arrInquireItem = [];
      // FILTER SELECTED INQUIRY CATEGORY
      const item = this.radioValue.filter(
        (el) => el.Japanese == val || el.English == val
      )[0].id;

      // GET RECORD FROM API
      CategoryInformationTable.getCategory(item).then((res) => {
        if (res.data != "No Data Found!") {
          // FILTER INQUIRY CATEGORY BASED ON USER TYPE
          res.data = res.data.filter(
            (el) => el.EmailIchijo || el.IchijoIncharge
          );

          this.arrInquireItem = res.data;
        }
      });
    },

    //*************************************//
    //     MANAGE FILES AND PREVIEW       //
    //************************************//

    handleFileUploads() {
      if (this.attachment.length == this.tempFile.length) return; //RETURN IF ALREADY HAVE ATTACHMENT

      this.files = []; //FILES TO BE SAVE IN S3
      this.attachment = []; //ATTACHMENT NAME TO BE SAVE IN DATABASE
      for (let x in this.tempFile) this.files.push(this.tempFile[x].file);
      // CHECK FOR ATTACHMENT
      if (this.files.length) {
        for (let i = 0; i < this.files.length; i++) {
          const attachment = this.files[i].name;
          this.attachment.push(attachment);
          let previewFile = URL.createObjectURL(this.files[i]);
          // PREVIEW 3 TYPES OF EXTENTION
          if (
            this.files[i].name.split(".")[1].toLowerCase() == "png" ||
            this.files[i].name.split(".")[1].toLowerCase() == "jpeg" ||
            this.files[i].name.split(".")[1].toLowerCase() == "pdf"
          )
            window.open(previewFile);
        }
      }
    },
    //*************************************//
    //          DELETE FILES               //
    //************************************//

    removeFileUpload() {
      this.files = []; //FILES TO BE SAVE IN S3
      this.attachment = []; //ATTACHMENT NAME TO BE SAVE IN DATABASE
      for (let x in this.$refs.pond.getFiles())
        this.files.push(this.$refs.pond.getFiles()[x].file);
      // CHECK FOR ATTACHMENT
      if (this.files.length) {
        for (let i = 0; i < this.files.length; i++) {
          const attachment = this.files[i].name;
          this.attachment.push(attachment);
        }
      }
    },
    //*************************************//
    //       MANAGE APP RESIZE            //
    //************************************//

    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    //*************************************//
    //       FOR SAVE DRAFT TRIGGER        //
    //************************************//

    SaveDraft() {
      this.draftDialog = true;
      this.toStore.UserID = this.userID;
      this.toStore.DateCreated = moment().format("YYYY-MM-DD HH:mm:ss");
    },
    //*************************************//
    //       SAVE COMPOSED DRAFT          //
    //************************************//

    async insertDraft() {
      // CHECK IF NULL DRAFT NAME
      if (!this.draftName)
        return this.$toast.error(this.$t("dialog.draftName"), objects.alert);
      else {
        this.waitingDialog = true;

        // DELETE AND VALIDATE RECORDS
        delete this.toStore.Department;
        this.toStore.DraftName = this.draftName;
        if (this.customerCode) this.toStore.CustomerCode = this.customerCode;
        if (this.rulebookNumber)
          this.toStore.RuleBookNumber = this.rulebookNumber;

        // CHECK FOR ATTACHMENT
        if (this.files.length > 0) {
          const dateToday = moment().format("YYYY/MM/DD");
          this.toStore.Path = `Drafts/${dateToday}`; //BUILD S3 PATH OF ATTACHMENT

          // FUNCTION FOR UPLOAD IN S3
          attachment.upload(
            "Drafts",
            this.files,
            (fileName) => {
              this.toStore.Attachment = fileName;
            },
            () => ClearFunction()
          );
        }

        // CHECK FOR SAME DRAFT NAME IN DATABASE
        DraftTable.checkDraft(this.toStore.UserID, this.draftName).then(
          async (res) => {
            if (res.data == "No record Found")
              await DraftTable.Insert(this.toStore).then(() => {
                if (this.files.length === 0) ClearFunction();
              });
            else {
              this.$toast.error(this.$t("dialog.duplicate"), objects.alert);
              this.waitingDialog = false;
            }
          }
        );
      }

      // ClEAR RECORD FUNCTION
      const ClearFunction = () => {
        this.toStore = {};
        this.files = [];
        this.tempFile = [];
        this.$refs.pond.removeFiles();
        this.arrInquireItem = [];
        this.attachment = [];
        this.content = "";
        this.customerCode = "";
        this.rulebookNumber = "";
        this.toStore.Department = "HRD開発";
        this.draftName = "";
        this.draftDialog = false;
        this.waitingDialog = false;
        this.getHoliday();
        this.$toast.success(this.$t("dialog.success"), objects.alert);
      };
    },
  },
};
</script>

<style lang="scss">
.myCard111 {
  max-height: calc(140vh - 350px);
}
.bottomArrangement111 {
  margin-bottom: -3%;
}
</style>

